import DropLanguage from './drop-language.component';
import { useNavigate, Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { STORAGE } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useMemo(() => LocalStorageService.get(STORAGE.SESSION), []);

  const logout = () => {
    LocalStorageService.clear();
    navigate('/auth/login');
  };
  return (
    <>
      <div
        id="kt_header"
        className="header"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      >
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <div className="d-flex align-items-center d-lg-none">
              <div
                className="btn btn-icon btn-active-color-primary ms-n2 me-1"
                id="kt_aside_toggle"
              >
                <i className="ki-duotone ki-abstract-14 fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
            <Link to="/dashboard" className="d-lg-none">
              <img alt="Logo" src="/assets/media/logos/logo-c.svg" className="mh-40px" />
            </Link>
            <div
              className="btn btn-icon w-auto ps-0 btn-active-color-primary d-none d-lg-inline-flex me-2 me-lg-5 active"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
            >
              <i className="ki-duotone ki-black-left-line fs-2x fw-bolder rotate-180">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div className="d-flex align-items-stretch me-1"></div>
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-2">
                <div
                  className="d-flex align-items-center ms-2 ms-lg-3"
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    className="cursor-pointer symbol symbol-50px symbol-circle symbol-lg-50px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <img alt="profile-picture" src="/assets/media/avatars/av1.png" />
                    <div className="bg-primary position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                  </div>
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-375px"
                    data-kt-menu="true"
                  >
                    <div className="card-toolbar">
                      <div className="d-flex align-items-center ms-1 ms-lg-2">
                        <a
                          className="btn btn-icon btn-active-light-primary w-40px h-40px w-md-40px h-md-40px"
                          data-kt-menu-attach="parent"
                          data-kt-menu-placement="left-start"
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                        >
                          <i className="ki-duotone ki-night-day theme-light-show fs-2x">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                            <span className="path7" />
                            <span className="path8" />
                            <span className="path9" />
                            <span className="path10" />
                          </i>
                          <i className="ki-duotone ki-moon theme-dark-show fs-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </a>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                          data-kt-element="theme-mode-menu"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3 my-0">
                            <a
                              className="menu-link px-3 py-2"
                              data-kt-element="mode"
                              data-kt-value="light"
                              href="#"
                            >
                              <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-duotone ki-night-day fs-2">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                  <span className="path7" />
                                  <span className="path8" />
                                  <span className="path9" />
                                  <span className="path10" />
                                </i>
                              </span>
                              <span className="menu-title">{t('theme.brightness')}</span>
                            </a>
                          </div>
                          <div className="menu-item px-3 my-0">
                            <a
                              className="menu-link px-3 py-2"
                              data-kt-element="mode"
                              data-kt-value="dark"
                              href="#"
                            >
                              <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-duotone ki-moon fs-2">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                              <span className="menu-title">{t('theme.dark')}</span>
                            </a>
                          </div>
                          <div className="menu-item px-3 my-0">
                            <a
                              className="menu-link px-3 py-2"
                              data-kt-element="mode"
                              data-kt-value="system"
                              href="#"
                            >
                              <span className="menu-icon" data-kt-element="icon">
                                <i className="ki-duotone ki-screen fs-2">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                </i>
                              </span>
                              <span className="menu-title">{t('theme.system')}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-center flex-column mbs-profile">
                      <span className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">
                        <div>
                          {session?.email ? (
                            session.email
                          ) : (
                            <span className="text-muted fs-6">{t('general.no-email')}</span>
                          )}
                        </div>
                      </span>
                      <div className="fw-semibold text-primary mb-7 fs-2">{session.profile}</div>
                      <div className="symbol symbol-90px symbol-circle mb-2">
                        <img alt="profile-picture" src="/assets/media/avatars/av1.png" />
                        <div className="bg-primary position-absolute border border-4 border-body h-20px w-20px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                        <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-20px w-20px ms-n3 mt-n3" />
                      </div>
                      <span className="fs-1 text-gray-800 text-hover-primary mb-7">
                        ¡{t('general.hi')}, {session.full_name}!
                      </span>
                      <div className="d-flex flex-center flex-wrap mb-5">
                        <Link
                          to="/channels"
                          className="btn btn-lg btn-light-secondary btn-flex btn-center rounded-left no-border-right w-150px"
                          data-kt-follow-btn="true"
                        >
                          <i className="ki-duotone ki-abstract-28 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <span className="indicator-label">{t('general.channels')}</span>
                        </Link>
                        <a
                          className="btn btn-lg btn-light-danger btn-flex btn-center ms-2 rounded-right no-border-left w-150px"
                          data-kt-follow-btn="true"
                          onClick={logout}
                        >
                          <i className="ki-duotone ki-exit-right fs-2x">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <span className="indicator-label">{t('header.title-3')}</span>
                        </a>
                      </div>
                      <DropLanguage />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
